import { CovidState } from './types';

const state: CovidState = {
    covidPage: {
        covidIndivisualData: [],
        covidIndivisualCount: 0,
        covidGroupData: [],
        covidGroupCount: 0,
        dailyCovidData: [],
        weeklyCovidData: [],
        monthlyCovidData: [],
        yearlyCovidData: [],
        tableCovidData: [],
        covidRegionWiseData: [],
        covid19BulkIndividualData: [],
        covid19BulkGroupData: [],
        covid19IndividualEditData: {},
        covid19GroupEditData: {},
    },
};
export default state;
