/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { _cs } from '@togglecorp/fujs';

import MapDownloadButton from '#components/MapDownloadButton';
import { MapChildContext } from '../context';
import useDimension from '../useDimension';
import styles from './styles.scss';

interface Props {
    className?: string;
}

// TODO: make container responsive
const MapContainer = (props: Props) => {
    const { className, activeLayers, onPendingStateChange } = props;
    const { mapContainerRef, map } = useContext(MapChildContext);
    const rect = useDimension(mapContainerRef);
    // console.log('active layers', activeLayers);
    useEffect(
        () => {
            if (map) {
                map.resize();
            }
        },
        [rect, map],
    );
    // const handleCancelDownload = () => {
    //     document.exitFullscreen();
    // };
    // useEffect(() => {
    //     if (window.innerHeight !== screen.height) {
    //         const myElements = document.getElementById('realMap123');
    //         console.log('My final element', myElements);
    //         myElements.style.setProperty('height', 'unset', 'important');
    //         myElements.style.setProperty('width', 'unset', 'important');
    //         myElements.style.setProperty('position', 'unset', 'important');
    //         myElements.style.setProperty('top', 'unset', 'important');
    //         myElements.style.setProperty('background-color', 'transparent', 'important');
    //         myElements.style.setProperty('flex-grow', '1', 'important');
    //     }
    // }, [window.innerHeight, map]);

    return (
        <div
            ref={mapContainerRef}
            className={_cs(className, styles.map)}
            id="realMap123"

        >
            <div style={{ position: 'absolute', bottom: '0', left: '0', zIndex: 2 }}>
                {/* <button type="button">Download</button> */}
                {/* <MapDownloadButton
                    // className={styles.mapDownloadButton}
                    className={styles.downloadButton}
                    // transparent
                    title="Download current map"
                    // iconName="download"
                    onPendingStateChange={
                        onPendingStateChange
                    }
                    activeLayers={activeLayers}

                />
                <button type="button" onClick={handleCancelDownload}>Back</button> */}
            </div>


        </div>
    );
};

export default MapContainer;
