/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import mapboxgl from 'mapbox-gl';
import { useContext, useEffect, useState, useRef } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';

import { Draw } from './type';

import { MapChildContext } from './context';

const noop = () => {};

type Mode = 'simple_select' | 'direct_select' | 'draw_point' | 'draw_line_string' | 'draw_polygon';

interface EditEvent {
    features: mapboxgl.MapboxGeoJSONFeature[];
}

interface ModeChangeEvent {
    mode: Mode;
}


interface Props {
    queryStringParams: string | undefined;
    polygonDrawAccessableRoutes: string[];
    drawRefState: boolean;
    resetDrawState: () => void;
}


const MapShapeEditor = (props: Props) => {
    const {
        queryStringParams,
        polygonDrawAccessableRoutes,
        drawRefState,
        resetDrawState,
    } = props;
    const {
        map,
        mapStyle,
        isMapDestroyed,
    } = useContext(MapChildContext);


    const drawRef = useRef<Draw | undefined>();
    const [rectangleBoundingBox, setrectangleBoundingBox] = useState<[any, any]>([]);
    const drawToZoom = () => {
        if (!drawRefState) return;
        if (drawRef.current) {
            drawRef.current.changeMode('draw_rectangle');
        }
        if (map && drawRef.current) {
            map.on('draw.create', (event) => {
                const firstBbox = event.features[0].geometry.coordinates[0][0];
                const secondBbox = event.features[0].geometry.coordinates[0][2];
                setrectangleBoundingBox([firstBbox, secondBbox]);
            });
        }
    };

    const drawToZoomReset = () => {
        if (drawRef.current) {
            drawRef.current.changeMode('simple_select');
        }
    };
    const checkQueryParams = () => {
        if (queryStringParams && polygonDrawAccessableRoutes.includes(queryStringParams)) {
            return true;
        }
        return false;
    };
    // Create and destroy control
    useEffect(
        () => {
            if (!map || !mapStyle) {
                return noop;
            }

            const draw = new MapboxDraw({
                displayControlsDefault: true,
                controls: {
                    polygon: checkQueryParams(),
                    trash: checkQueryParams(),
                    point: false,
                    line_string: false,
                    combine_features: false,
                    uncombine_features: false,

                },
                modes: {
                    ...MapboxDraw.modes,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    draw_rectangle: DrawRectangle,
                },
            });


            map.addControl(
                draw,
                'bottom-right',
            );

            drawRef.current = draw as Draw;

            return () => {
                if (!isMapDestroyed()) {
                    map.removeControl(draw);
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [map, mapStyle, isMapDestroyed, drawRefState, queryStringParams],
    );

    useEffect(() => {
        if (drawRefState && drawRef.current) {
            document.body.addEventListener('keyup', (event) => {
                if (event.code === 'Escape') {
                    drawToZoomReset();
                    if (map) {
                        map.getCanvasContainer().style.cursor = 'default';
                    }
                    resetDrawState();
                }
            });
            if (map) {
                map.getCanvasContainer().style.cursor = 'crosshair';
            }
            drawToZoom();
        }

        if (!drawRefState) {
            drawToZoomReset();
            if (map) {
                map.getCanvasContainer().style.cursor = 'default';
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawRefState]);


    useEffect(() => {
        // ensuring rectangleBoundingBox is not empty
        if (drawRef && drawRef.current) {
            if (map && rectangleBoundingBox.length > 0 && rectangleBoundingBox[0].length > 0
         && rectangleBoundingBox[1].length > 0) {
                map.fitBounds(rectangleBoundingBox);
                drawRef.current.deleteAll();
                map.getCanvasContainer().style.cursor = 'default';
                resetDrawState();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rectangleBoundingBox]);


    return null;
};


MapShapeEditor.defaultProps = {
    geoJsons: [],
};

export default MapShapeEditor;
