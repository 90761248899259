/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { IncidentState } from './types';

const state: IncidentState = {
	incidentPage: {
		incidentTableData: [],
		incidentDataCount: null,
		incidentFormEditData: {},
	},
};
export default state;
