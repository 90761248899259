import { HealthInfrastructureState } from './types';

const state: HealthInfrastructureState = {
    healthInfrastructurePage: {
        resourceID: 0,
        loadingInv: false,
        inventoryErr: { message: '' },
        inventoryData: [],
        inventoryItem: [],
        invItemSuccess: false,
        invItemError: false,
        healthDataLoading: false,
        healthTableData: [],
        healthDataErr: { message: '' },
        deletePending: false,
        deleteSuccess: false,
        deleteFailure: false,
        deleteError: { message: '' },
        healthFormEditData: {},
        formDataEditPending: false,
        formDataEditError: { message: '' },
        formDataEditSuccess: false,
        healthOverviewTableData: {},
        healthOverviewChartData: {},
        healthFormLoader: false,
        healthUploadLoading: false,
        healthError: false,
        uploadData: [],
        uploadDataLoading: false,
        uploadDataError: { message: '' },
        validationError: null,
        healthDataCount: null,
    },
};
export default state;
