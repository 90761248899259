// import { currentStyle } from '#rsu/styles';

const styleProperties = {
    colorBackground: '#f2f2f2',
    colorPrimary: '#216cd7',

    widthScrollbar: '4px',

    widthLeftPanel: '480px',
    widthRightPanel: '256px',
    widthRightPanelLarge: '360px',
    widthNavbar: '60px',
    widthResponseFilters: '240px',
    heightNavbar: '56px',
    colorAccent: '#e35163',
    colorAccentLight: '#ffb3c1',
    colorTextAccentHint: '#e04556',
    radiusPanelBorder: '5px',
    heightTimelineMainContent: '112px',
    panelBorderRadius: '5px',
    colorBackgroundNewItem: 'rgba(79, 195, 247, .09)',
    colorBackgroundNewItemHover: 'rgba(79, 195, 247, .2)',

    fontWeightLight: '300',
    fontWeightMedium: '400',
    fontWeightBold: '700',

    fontSizeMedium: '12px',
    fontSizeSmall: '11px',
    fontSizeExtraSmallAlt: '10px',
    fontSizeExtraSmall: '10px',
    fontSizeUltraLarge: '32px',
    fontSizeUltraLargeAlt: '48px',

    widthContactDisplayPicture: '96px',
    heightContactDisplayPicture: '96px',

    widthRiskInfoAttributeIcon: '24px',
    heightRiskInfoAttributeIcon: '24px',

    spacingPageBottom: '36px',

    widthManualImagePreview: '72px',
    heightManualImagePreview: '96px',

    widthMapboxControlLeftTop: '0',
    heightMapboxControlLeftTop: '0',

    widthRealtimeFilter: '300px',
    colorRiskInfoIcon: '#777',

    colorTextLight: 'rgba(0, 0, 0, 0.5)',

    colorLogoRed: '#e35163',
    colorLogoBlue: '#216cd7',
    colorTextOnLogoBlue: '#fff',
    colorTextOnLogoRed: '#fff',

    colorBoxShadow: 'rgba(189,187,189,0.59)',
    colorPrimaryBlue: '#029aef',

    fontSizeJumbo: '32px',
    fontSizeJumboAlt: '36px',
    fontSizeExtraJumbo: '45px',

    colorBackgroundHover: 'rgba(200, 100, 100, 0.06)',
    colorBackgroundAccentHint: 'rgba(200, 100, 100, 0.06)',
};

export default styleProperties;
