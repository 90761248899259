import { NotificationState } from './types';

const state: NotificationState = {
    notificationPage: {
        notificationData: [],
        notificationError: {},
        notificationsPending: false,
        showNotification: false,
    },
};
export default state;
